import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { isSameCountry, isSameRegion } from '@core/helpers/country-validations';
import { CountryContext } from '@core/providers/country/country-provider';
import DomPurify from '@core/helpers/dom-purify';
import ParagraphComponent from '@core/helpers/paragraph-helpers';
import ContentBlur from '@core/helpers/content-blur';

import Hero from '@shared/hero/hero';
import TechnologiesParagraph from '@shared/technologies';
import ClientsParagraph from '@shared/clients';
import Banner from '@shared/banner/banner';
import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';
import BannerAdvertising from '@shared/banner/banner-advertising';

import { Graphql } from '@models/graphql';
import { Country } from '@models/country';

import imgTestBanner from '@assets/images/banner-success-stories.png';

const { successStory: crumbs } = require('@config/breadcrumbs');

type SuccessStoryProps = {
  language: string,
  data: {
    successStory: Graphql
  }
};

export default function SuccessStory({ data, language }: SuccessStoryProps): React.ReactElement {
  const { successStory } = data;
  const { t } = useTranslation();

  const paragraphs = successStory?.relationships?.paragraphs?.map(
    (node) => ParagraphComponent({ ...node, path: successStory.path }, language)
  );
  const nodeTechnologiesParagraph = {
    title: 'Technologies',
    relationships: {
      technologies: successStory?.relationships?.technologies || []
    }
  };

  const nodeClientsParagraph = {
    id: 'clients-paragraph',
    title: 'Client',
    clientsStyle: 'individual',
    relationships: {
      clients: [successStory?.relationships?.client]
    }
  } as Graphql;

  const nodeSuccessStory = {
    id: 'success-story-banner',
    title: t('Did you like this story?'),
    clientsStyle: 'individual',
    type: 'paragraph__paragraph_banner_advertising',
    backgroundImage: imgTestBanner,
    downloadable: true,
    isExternal: true,
    link: successStory?.relationships?.pdf?.relationships?.document?.uri?.url ? {
      title: t('Click to download'),
      uri: `${process.env.GATSBY_DEFAULT_URL}/${encodeURIComponent(successStory.relationships.pdf.relationships.document.uri.url)}`
    } : null
  } as Graphql;

  const getContent = (isValidCountry: boolean) => {
    const partialContent = (
      <>
        { successStory?.relationships?.client && (
          <div className="clients-container mb-5">
            <ClientsParagraph node={nodeClientsParagraph} />
          </div>
        )}
        { successStory?.relationships?.banner && (
          <div className="banner-container mb-4">
            <Banner node={successStory.relationships.banner} />
          </div>
        )}
      </>
    );

    if (!isValidCountry) {
      return partialContent;
    }

    return (
      <>
        {partialContent}
        { successStory?.wysiwyg?.processed && (
          <div className="container wysiwyg mb-5">
            <DomPurify text={successStory.wysiwyg.processed} />
          </div>
        )}
        {
          successStory?.relationships?.technologies
          && successStory.relationships.technologies.length > 0
          && (
            <div className="technologies-container pt-4 mb-5">
              <TechnologiesParagraph node={nodeTechnologiesParagraph as Graphql} />
            </div>
          )
        }
        { paragraphs && paragraphs.length > 0 && (
          <div className="page-components-container">{paragraphs}</div>
        )}
        { successStory?.relationships?.pdf && (
          <BannerAdvertising node={nodeSuccessStory} />
        )}
      </>
    );
  };

  const getContentByCountry = (country: Country) => {
    const countries = successStory?.relationships?.countries || [];
    const isValidCountry = isSameCountry(country, countries) || isSameRegion(country, countries);
    const content = getContent(isValidCountry);

    if (!isValidCountry) {
      return <ContentBlur content={content} />;
    }

    return content;
  };

  return (
    <CountryContext.Consumer>
      {
        (country: Country) => (
          <div className="success-story--page">
            { successStory?.relationships?.hero && (
              <div className="hero-container mb-5">
                <Hero node={successStory.relationships.hero} />
              </div>
            )}
            <Breadcrumb crumbs={crumbs.concat([{ label: successStory.title, link: '#' }])} />
            {getContentByCountry(country)}
          </div>
        )
      }
    </CountryContext.Consumer>
  );
}

export const query = graphql`
query($id: String!) {
  successStory: nodeSuccessStory(id: { eq: $id }) {
    id
    title
    wysiwyg: body {
      processed
      summary
    }
    path {
      alias
      langcode
    }
    relationships {
      countries: field_countries {
        country: name
        countryCode: field_country_code
        tid: drupal_internal__tid
        langcode
        status
      }
      client: field_client {
        title
        link: field_link {
          title
          uri
        }
        relationships {
          image: field_image {
            name: filename

            attributes: field_media_image {
              alt
              title
            }
          }
        }
      }
      technologies: field_ref_technologies {
        id
        title
        link: field_link {
          title
          uri
        }
        relationships {
          image: field_thumbnail {
            name

            attributes: field_media_image {
              alt
              title
            }
          }
        }
      }
      pdf: field_pdf_document {
        relationships {
          document: field_media_document {
            uri: localFile {
              url: publicURL
            }
          }
        }
      }
      hero: field_hero {
        ...HeroParagraph
      }
      banner: field_banner {
        ...BannerParagraph
      }
      paragraphs: field_content_main {
        type: __typename
        ...CtaParagraph
        ...HeroParagraph
        ...HeroSliderParagraph
        ...BannerParagraph
        ...BannerTalentParagraph
        ...BannerAdvParagraph
        ...QuoteSliderParagraph
        ...QuoteParagraph
        ...IntroTextParagraph
        ...IntroBlockParagraph
        ...TimelineParagraph
        ...CardsParagraph
        ...BoxesParagraph
        ...GridParagraph
        ...ClientsParagraph
        ...TechnologiesParagraph
        ...TabsParagraph
        ...TableParagraph
        ...WysiwygParagraph
        ...ViewParagraph
        ...FeaturedContentParagraph
        ...BulletsParagraph
        ...BannerFormParagraph
        ...BannerTalentApplyParagraph
        ...EmergencyModalParagraph
        ...BannerVideoParagraph
        ...WebFormParagraph
        ...CollageParagraph
        ...FabParagraph
        ...ImageSliderParagraph
        ...OfficesParagraph
      }
    }
  }
}`;
